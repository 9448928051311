
import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { ToastContainer } from 'react-toastify'

import './App.scss'
import 'react-toastify/dist/ReactToastify.css'

import InternalRouteWrapper from './components/shared/InternalRouteWrapper'
import ExternalRouteWrapper from './components/shared/ExternalRouteWrapper'

const Home = lazy(() => import('./pages/general/Home'))

//const Privacy = lazy(() => import('./pages/general/Privacy'))
//const Terms = lazy(() => import('./pages/general/Terms'))
//const Contact = lazy(() => import('./pages/general/Contact'))
const Login = lazy(() => import('./pages/general/Login'))
//const Purchase = lazy(() => import('./pages/general/Purchase'))
const Trades = lazy(() => import('./pages/general/Trades'))
const Portfolio = lazy(() => import('./pages/general/Portfolio'))
const Profile = lazy(() => import('./pages/general/Profile'))
class App extends React.Component {

  render() {
    return (
      <Router>
        <ToastContainer />
        <Suspense fallback={<></>}>
          <Switch>
            {/* authenticated pages */}
            <InternalRouteWrapper path="/trades" component={Trades} />
            <InternalRouteWrapper path="/portfolio" component={Portfolio} />
            <InternalRouteWrapper path="/profile" component={Profile} />

            {/* combo pages */}
            {/* <SharedRouteWrapper path="/privacy-policy" component={Privacy} />
            <SharedRouteWrapper path="/terms-of-use" component={Terms} />
            <SharedRouteWrapper path="/contact-us" component={Contact} />
            <SharedRouteWrapper path="/purchase" component={Purchase} /> */}

            {/* unauthenticated pages */}
            <ExternalRouteWrapper path="/signup" isLogin={false} component={Login} />
            <ExternalRouteWrapper path="/login" isLogin={true} component={Login} />
            <ExternalRouteWrapper path="/" component={Home} />

          </Switch>
        </Suspense>
      </Router>
    )
  }
}

export default App
