import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/performance'

const firebaseConfig = {
  apiKey: "AIzaSyDbix_eUxU4-WsCQd7zG2EgH8XB-i0i11s",
  authDomain: "cryptocostaverage.app",
  projectId: "coinbase-dca-9e062",
  storageBucket: "coinbase-dca-9e062.appspot.com",
  messagingSenderId: "914808949216",
  appId: "1:914808949216:web:336a55c0dc2282519dc385",
  measurementId: "G-MG5VW58D3D"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
} else {
  firebase.app()
}

firebase.analytics()
firebase.performance()

// prod
const db = firebase.firestore()
const functions = firebase.functions()
// const firebaseBaseURL = 'https://us-central1-financial-app-77c2c.cloudfunctions.net/'

//local
// firebase.functions().useEmulator("localhost", 5000)
// const firebaseBaseURL = 'http://localhost:5001/financial-app-77c2c/us-central1/'

//#region Auth

export const signInWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider()
  return firebase.auth().signInWithPopup(provider)
}

export const signOut = () => {
  return firebase.auth().signOut()
}

export const onAuthStateChanged = (callback) => {
  firebase.auth().onAuthStateChanged((user => {
    callback(user)
  }))
}

//#endregion

//#region User

export const updateUser = (name, email, user) => {
  const currentUserDoc = db.collection("users").doc(user.uid)

  return currentUserDoc.get().then((document) => {
    const data = {
      userId: user.uid,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }

    currentUserDoc.set(data, { merge: true })

    firebase.analytics().setUserId(user.uid)

    if (name != null) {
      //database
      currentUserDoc.update({ name: name })

      //auth
      user.updateProfile({ displayName: name })
    }

    if (email != null) {
      //database
      currentUserDoc.update({ email: email }, { merge: true })

      //auth
      user.updateEmail(email)
    }

    if (!document.exists || document.data().createdAt === null || document.data().createdAt === undefined) {
      currentUserDoc.update({ createdAt: firebase.firestore.FieldValue.serverTimestamp() })
    }

  })

}

export const getCurrentUser = () => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  return db.collection("users").doc(currentUser.uid).get().then(doc => {
    return ({ ...doc.data(), id: doc.id })
  })
}

export const subscribeToCurrentUser = (callback) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  db.collection("users").doc(currentUser.uid).onSnapshot(doc => {
    callback({ ...doc.data(), id: doc.id })
  })
}

export const subscribeToUserTotals = (callback) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  db.collection("userTotals").doc(currentUser.uid).onSnapshot(doc => {
    callback(doc.data())
  })
}


//#endregion

//#region Events

export const logEvent = (eventName, params) => {
  firebase.analytics().logEvent(eventName, params)
}

//#endregion

//#region Contact

//#region Keys

export const addOrUpdateKey = (key, secret, passphrase) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()

  return db.collection("coinbaseKeys").doc(currentUser.uid).set({ key, secret, passphrase, userId: currentUser.uid, updatedAt: timestamp })
}

export const subscribeToKey = (callback) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  db.collection("coinbaseKeys").doc(currentUser.uid).onSnapshot(doc => {
    callback(doc.data())
  })
}

export const deleteKey = () => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  return db.collection("coinbaseKeys").doc(currentUser.uid).delete()
}

//#endregion

//#region Funding

export const addOrUpdateSelectedFunding = (name, accountId, withdrawal) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()

  return db.collection("coinbaseFundingAccounts").doc(currentUser.uid).set({ name, accountId, withdrawal, userId: currentUser.uid, updatedAt: timestamp })
}

export const subscribeToSelectedFunding = (callback) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  db.collection("coinbaseFundingAccounts").doc(currentUser.uid).onSnapshot(doc => {
    callback(doc.data())
  })
}

export const getFundingAccounts = () => {
  const getFundingAccountsCallable = functions.httpsCallable('getFundingAccounts')

  return getFundingAccountsCallable().then((response) => {
    return response.data
  }).catch((error) => {
    console.log(error)
    return {}
  })
}

//#endregion

//#region Trades

export const subscribeToTrades = (callback) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }

  db.collection("trades").where('userId', '==', currentUser.uid).onSnapshot(snapshot => {
    callback(snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } }))
  })
}

export const addTrade = (product, frequency) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()

  return db.collection("trades").add({ product, frequency, userId: currentUser.uid, createdAt: timestamp, updatedAt: timestamp })
}

export const updateTrade = (id, product, frequency) => {
  const currentUser = getAuthUser()
  if (currentUser == null) { return }
  const timestamp = firebase.firestore.FieldValue.serverTimestamp()

  return db.collection("trades").doc(id).update({ product, frequency, userId: currentUser.uid, updatedAt: timestamp })
}

export const deleteTrade = (id) => {
  return db.collection("trades").doc(id).delete()
}

//#endregion

//#region Helpers

const getAuthUser = () => {
  let currentUser = JSON.parse(localStorage.getItem('user'))

  if (currentUser == null) {
    currentUser = firebase.auth().currentUser
  }

  return currentUser || null

}

//#endregion
