import React from 'react'
import { withRouter } from 'react-router-dom'

import * as BS from 'react-bootstrap'

import { subscribeToCurrentUser, onAuthStateChanged } from '../../utils/firebaseUtils'

class Menu extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      isLoggedIn: false,
      user: {},
      isMenuOpen: false,
      institutions: [],
      connectedAssets: [],
      rentals: [],
      subscription: {},
    }

  }

  componentDidMount() {
    onAuthStateChanged((currentUser) => {
      if (currentUser) {
        this.setState({ isLoading: false, isLoggedIn: true })

        subscribeToCurrentUser(user => {
          this.setState({ user: user })
        })

      }
    })
  }

  render() {
    const { isLoggedIn, isLoading } = this.state

    if (!isLoading && !isLoggedIn) {
      return (<></>)
    }

    return (
      <BS.Navbar bg="light" expand="lg">
        <BS.Container>
          <BS.Navbar.Brand href="/trades">Crypto Cost Average</BS.Navbar.Brand>
          <BS.Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <BS.Navbar.Collapse id="responsive-navbar-nav">
            <BS.Nav className="mr-auto">
              <BS.Nav.Link href="/trades">Trades</BS.Nav.Link>
              <BS.Nav.Link href="/portfolio">Portfolio</BS.Nav.Link>
              <BS.Nav.Link href="/profile">Profile</BS.Nav.Link>
            </BS.Nav>
          </BS.Navbar.Collapse>
        </BS.Container>
      </BS.Navbar>
    )
  }
}

export default withRouter(Menu)