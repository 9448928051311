import React, { useEffect } from 'react'
import { Route, withRouter } from "react-router-dom"

import { onAuthStateChanged } from '../../utils/firebaseUtils'

const ExternalRouteWrapper = ({ component: Component, history, ...rest }) => {

  useEffect(() => {
    onAuthStateChanged(user => {
      if (user) {
        history.replace('/trades')
        return
      }
    })
  }, [history])

  return (
    <Route {...rest} render={props => {
      return (<Component {...props} {...rest} />)
    }}
    />
  )
}

export default withRouter(ExternalRouteWrapper)
